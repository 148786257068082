@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.contact-form {
  padding: 4rem 5rem;
  margin: 1.5rem;
  background-color: $secondary-background;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    align-items: start;
  }

  &__header {
    padding-block: 2rem;
    font-size: 2rem;
    font-weight: 400;
  }

  &__form {
    max-width: 45rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__group {
    margin-bottom: 1rem;
    width: 100%;

    &--half {
      width: 100%;

      @include tablet {
        display: flex;
        justify-content: space-between;

        > * {
          flex: 1;

          &:not(:first-child) {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  &__label {
    display: block;
    margin: 0.8rem 0;
  }

  &__input,
  &__textarea {
    width: 100%;
    padding: 0.8rem 1rem;
    border: 1px solid #e4e4e4;
    font-family: "Outfit";
    font-size: 0.9rem;

    &:focus {
      border-color: #666666;
    }

    &::placeholder {
      font-family: "Outfit";
      font-size: 0.9rem;
      color: #888888;
    }
  }

  &__textarea {
    height: 10rem;
    resize: vertical;
  }

  &__button {
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.2rem;
    width: 100%;
    padding: 0.9rem 1rem;
    margin: 2rem 0;
    background-color: #000000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @include tablet {
      width: auto;
    }

    &:hover {
      background-color: #333333;
    }
  }
}