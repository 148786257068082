@import "../partials/_mixins.scss";
@import "../partials/_variables.scss";

.project {
  padding: 1rem;

  @include tablet {
    padding: 2rem;
  }

  &__cover {
    width: 100%;
  }

  &__content {
    @include tablet {
      display: flex;
    }
  }

  &__header {
    font-size: 2rem;
    padding: 0.5rem 1rem;
    font-weight: 200;
    letter-spacing: 0.1rem;

    @include tablet {
      font-size: 4rem;
      padding: 0.5rem 0;
    }
  }

  &__section {
    padding: 0.5rem 1rem;

    @include tablet {
      padding: 0.5rem 0;
      width: 50%;
    }

    &--flex {
      padding: 5rem 1rem;

      @include tablet {
        display: flex;
      }
    }

    @include desktop {
      padding-block: 1rem;
    }
  }

  &__subheader {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 400;
    padding-block: 1rem;

    @include tablet {
      width: 50%;
    }
  }

  &__tech,
  &__overview {
    padding: 0 2rem 5rem 0;
    font-weight: 200;
    line-height: 1.75rem;
  }

  &__link {
    text-decoration: underline;
    padding-top: 1rem;
    display: flex;

    &:hover {
      opacity: 0.6;
     }
  }

  &__link-icon {
    width: 1rem;
  }

  &__description {
    padding-block: 1rem;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.75rem;

    &--section {
      padding-block: 0.5rem;
    }

    @include tablet {
      width: 80%;
    }
  }

  &__mockup {
    width: 100%;
    padding-block: 1rem;
  }
}
