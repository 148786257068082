@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.hero {
  line-height: normal;
  padding: 3rem 1.5rem;

  @include tablet {
    line-height: 5rem;
    padding: 5rem 4rem;
    max-width: 80%;
  }

  &__title,
  &__subtitle {
    font-weight: 200;
  }

  &__title {
    font-size: 1.1rem;
    
    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__subtitle {
    max-width: 80rem;
    font-size: 1.5rem;

    @include tablet {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }

    @include desktop {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
  }
}
