@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.about {
  margin: 1.5rem;

  @include tablet {
    margin: 2rem 4rem;
  }

  &__header {
    padding-block: 0.3rem;
    font-size: 2rem;
    font-weight: 400;

    @include tablet {
      text-align: start;
    }
  }

  &__paragraph {
    font-size: 1rem;
    color: $secondary-text;
    max-width: 50rem;
    font-weight: 200;
    opacity: 0.7;

    @include tablet {
      font-size: 1.2rem;
    }
  }

  &__skills-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 2rem;

    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include desktop {
      max-width: 70rem;
    }
  }

  &__skills {
    padding-block: 1rem;

    ul {
      font-size: 1rem;
      font-weight: 200;
      opacity: 0.7;

      @include tablet{
        font-size: 1.2rem;
      }

      li {
        padding-block: 0.3rem;
      }
    }
  }

  &__subheader {
    padding-block: 0.3rem;
    font-size: 1.2rem;
    font-weight: 400;

    @include tablet {
      font-size: 1.4rem;
    }
  }
}
