// Import mixins and variables at the start
@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.projects {
  margin: 1rem;
  background: $primary-background;

  @include tablet {
    margin: 1.5rem;
  }

  &__header {
    font-size: 1.25rem;
    font-weight: 200;
    padding-left: 0.5rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    padding: 0.5rem;
    padding-block: 1rem;
    flex-grow: 1;

    &:hover {
      opacity: 0.8;
    }

    @include tablet {
      width: 50%;
      padding-block: 2rem;
    }
  }

  &__image-container {
    width: 100%;
    height: 15rem;

    @include tablet {
      height: 30rem;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  &__details {
    padding-top: 1rem;
  }

  &__name,
  &__type {
    text-align: center;
    font-weight: 400;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__type {
    color: $secondary-text;
    font-weight: 200;
    padding-block: 0.5rem;
    opacity: 0.7;

    @include tablet {
      font-size: 1rem;
    }
  }

  // Styling for individual project pages (More projects section)
  &__name--page {
    font-size: 1.5rem;
  }

  &__type--page {
    font-size: 1rem;
  }
}
