@font-face {
  font-family: "Outfit";
  src: url(../assets/fonts/Outfit-Bold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url(../assets/fonts/Outfit-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url(../assets/fonts/Outfit-Light.ttf);
  font-weight: 200;
  font-style: normal;
}
