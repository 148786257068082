$primary-background: #ffffff;
$secondary-background: #f4f4f4;
$primary-text: #1e1e1e;
$secondary-text: #232323;
$border: #1e1e1e;

// Media breakpoints
$mobile: 320px;
$tablet: 768px;
$desktop: 1280px;
