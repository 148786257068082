@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @include tablet {
    padding: 2rem 1.5rem;
    flex-direction: row;
    justify-content: space-between;
  }

  &__logo {
    cursor: pointer;
    width: 2rem;
    opacity: 0.6;
    padding-block: 0.7rem;

    &:hover {
      opacity: 1;
    }
  }

  &__socials {
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
    display: flex;

    &--item {
      padding: 1.2rem;

      &:hover {
        opacity: 0.6;
      }
    }

    @include tablet {
      flex-direction: row;
    }
  }
}
