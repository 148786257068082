@import "./_variables.scss";

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}