@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $primary-background;

  @include tablet {
    padding: 2rem;
  }

  &__logo {
    width: 3rem;

    &:hover {
      opacity: 0.6;
    }
  }

  &__hamburger-navigation {
    display: block;
    cursor: pointer;

    @include tablet {
      display: none;
    }
  }

  &__navigation {
    text-transform: uppercase;
    position: absolute;
    right: 0;
    top: 5rem;
    background-color: $primary-background;
    display: none;
    height: calc(100vh - 5rem);

    &.active {
      animation: slideIn 0.3s ease-in;
      width: 100%;
      display: block;
      z-index: 1;
      background-color: rgba($primary-background, 0.96);
    }

    ul {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    @include tablet {
      display: block;
      width: auto;
      top: 2.5rem;
      right: 1rem;
      height: fit-content;

      ul {
        padding: 0;
        flex-direction: row;
        position: static;
      }
    }
  }

  &__navigation-item {
    font-size: 1rem;
    padding-block: 0.5rem;
    letter-spacing: 0.1rem;
    padding: 0 1.2rem;

    @include tablet {
      font-size: 0.75rem;

      &:hover {
       opacity: 0.6;
      }
    }
  }
}

.bar1,
.bar2,
.bar3 {
  width: 1.7rem;
  height: 0.1rem;
  background-color: $border;
  margin: 0.5rem 0;
  transition: 0.4s;
}

.active .bar1 {
  transform: rotate(-45deg) translate(-7px, 6px);
}

.active .bar2 {
  opacity: 0;
}

.active .bar3 {
  transform: rotate(45deg) translate(-7px, -7px);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
