@import "../../partials/_mixins.scss";
@import "../../partials/_variables.scss";

.home {
  height: 100vh;
  background-color: $primary-background;

  &__name {
    font-weight: 200;
    font-size: 2rem;
    padding: 3rem;
  }
}
